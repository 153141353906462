import { Button } from '../../../shared-components/button';
import { Container } from '../../../shared-components/container';
import Footer from '../../../shared-components/footer/footer.component';
import { BasePage } from '../../base';
import { useVideoHook } from './tutorial-video.hook';
import './style.css';
import { useParams } from 'react-router-dom';
import { CongratulationComponent } from '../../bootstrap/components/congratulation/congratulation.component';
import { useContext, useEffect, useRef, useState } from 'react';
import { Preloader } from '../../../shared-components/preloader';
import { YouTubePlayer } from '../../../shared-components/youtube-player/youtube-player';
import { tutorialThumbnails } from './tutorial-videos.type';
import { ToasterContext } from '../../../context/toaster.context';

interface ThumbnailProps {
  onClick: () => void;
  stepNumber?: number;
  show: boolean;
}

const Thumbnail = ({ onClick, stepNumber, show }: ThumbnailProps) => {
  const thumbnailInfo = tutorialThumbnails.find(
    (item) => item.stepNumber === stepNumber
  );

  return (
    <div
      style={{
        visibility: show ? 'visible' : 'hidden',
      }}
      onClick={onClick}
      className="thumbnail-wrapper"
    >
      <div className="thumbnail-overlay">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM31 24L21 16V32L31 24Z"
            fill="white"
          />
        </svg>
      </div>
      {thumbnailInfo && <img src={thumbnailInfo.thumbnail} alt="thumbnail" />}
    </div>
  );
};

const getPageTitle = (type: string | null, step: number) => {
  if (type === 'hubspot') {
    return '';
  }
  return `${step}/5`;
};

export const TutorialVideo: React.FC = () => {
  const { stepNumber } = useParams();
  const step = stepNumber ? parseInt(stepNumber, 10) : 3;
  const { form, loading, videoData } = useVideoHook(step);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState<boolean>(false);
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);
  const ctx = useContext(ToasterContext);
  const playerRef = useRef<any>(null);
  const urlParams = new URLSearchParams(location.search);
  const type = urlParams.get('registration-type');

  useEffect(() => {
    const intervalId = setInterval(checkVideoTime, 1000);
    return () => clearInterval(intervalId);
  }, [ctx, step]);

  const onVideoEnd = () => {
    setShowNextButton(true);
    setShowVideo(false);
  };

  const onVideoPause = (ev: any) => {
    const {
      playerInfo: { duration, currentTime },
    } = ev.target;

    if (duration - currentTime < 15) {
      setShowNextButton(true);
    }

    if (currentTime > 15) {
      setShowSkipButton(true);
    }
  };

  const onReady = (event: any) => {
    playerRef.current = event.target;
  };

  const onVideoPlay = () => {
    setTimeout(() => {
      setShowSkipButton(true);
    }, 15000);
  };

  const checkVideoTime = () => {
    if (!playerRef.current) return;

    const currentTime = playerRef.current.getCurrentTime();
    const duration = playerRef.current.getDuration();

    const timeLeft = duration - currentTime || 16;
    setShowNextButton(timeLeft <= 15);
  };

  const handleSeek = (seconds: number) => {
    if (playerRef.current) {
      if (!showVideo) {
        setShowVideo(true);
      }
      playerRef.current.seekTo(seconds);
      if (seconds > 15) {
        setShowSkipButton(true);
      }
    }
  };

  return (
    <>
      <Preloader isLoading={loading} />
      <BasePage publicPage={true}>
        {loading ? null : (
          <>
            {step === 6 && <CongratulationComponent active={true} />}
            <Container extended={true}>
              <div className="video-header">
                {videoData?.stepNumber !== 6 && (
                  <div className="step-indicator">
                    {getPageTitle(type, step)}
                  </div>
                )}
                <div className="step-name">
                  <p className="name">{videoData?.stepName}</p>
                  {videoData?.stepNumber !== 6 && (
                    <p className="description">
                      Please, watch the series of short videos to understand how
                      the Fuel works.
                    </p>
                  )}
                </div>
                <div className="tutorial-header-action-buttons">
                  <button
                    className="tutorial-skip-button"
                    onClick={() => {
                      form.handleSubmit();
                    }}
                    style={{
                      visibility: showSkipButton ? 'visible' : 'hidden',
                    }}
                  >
                    Skip
                  </button>
                  <Button
                    mini
                    onClick={form.handleSubmit}
                    active={showNextButton}
                    children={'Next'}
                    style={{
                      width: '69px',
                      height: '36px',
                    }}
                    type="submit"
                  />
                </div>
              </div>
              <div className="video-container">
                <Thumbnail
                  onClick={() => {
                    setShowVideo(true);
                    playerRef.current?.playVideo();
                  }}
                  stepNumber={step}
                  show={!showVideo}
                />
                <YouTubePlayer
                  videoId={videoData?.videoId}
                  onReady={onReady}
                  onVideoEnd={onVideoEnd}
                  onVideoPause={onVideoPause}
                  onPlay={onVideoPlay}
                  showVideo={showVideo}
                />
              </div>
              {videoData?.timelines?.length && (
                <div className="video-timeline">
                  {videoData?.timelines?.map((item, idx) => (
                    <div className="video-timeline-item" key={idx}>
                      <button
                        onClick={() => {
                          handleSeek(item.seconds);
                        }}
                        className="video-timeline-time"
                      >
                        {item.timeLabel}
                      </button>
                      <p className="video-timeline-label">{item.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </Container>
            {step !== 2 && <Footer />}
          </>
        )}
      </BasePage>
    </>
  );
};
