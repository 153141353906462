import {
  getAuth,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { useContext } from 'react';
import { RoleContext } from '../../context/role.context';
import { instanceWithToken } from '../../tools/api';
import { UserRole } from '../../tools/api-services/types/client';
import { UserType } from '../../types/userType';

export const useNextLogin = () => {
  const navigate = useNavigate();
  const provider = new OAuthProvider('oidc.hivebrite');
  const { setRole, setRoleType } = useContext(RoleContext);
  const handlePostCall = async () => {
    const response = await DashboardHttpService.nextRegistrationCompleted();
    const result = await instanceWithToken.get<{
      role: UserRole;
      authenticated: boolean;
      type: UserType;
    }>('api/auth/is-authenticated');
    if (result.data.authenticated) {
      setRole(result.data.role);
      setRoleType(result.data.type);
      navigate(response.data.redirectUrl);
    }
  };

  const handleRedirectResult = async () => {
    try {
      const result = await getRedirectResult(getAuth());

      if (result) {
        const tkn = await result.user.getIdToken();
        localStorage.setItem('token', tkn);

        await handlePostCall();

        const url = new URL(window.location.href);
        window.history.replaceState({}, '', url.toString());
        sessionStorage.removeItem('openidInitialized');
      }
    } catch (error) {
      console.error(error);
      sessionStorage.removeItem('openidInitialized');
    }
  };

  const handleOpenidRedirect = async () => {
    await signInWithRedirect(getAuth(), provider);
  };

  const handlePopup = async () => {
    const res = await signInWithPopup(getAuth(), provider);
    const tkn = await res.user.getIdToken();
    localStorage.setItem('token', tkn);
    await handlePostCall();
  };

  return {
    handleOpenidRedirect,
    handleRedirectResult,
    handlePopup,
  };
};
