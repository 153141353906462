import { useContext } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { instanceWithToken } from '../../tools/api';
import ReactGA from 'react-ga4';
import { ToasterContext } from '../../context/toaster.context';
import { RoleContext } from '../../context/role.context';
import { useUser } from '../../context/user-details.context';
import { UserType } from '../../types/userType';

export const useLoginHook = () => {
  const navigate = useNavigate();
  const ctx = useContext(ToasterContext);
  const { setRole, setRoleType } = useContext(RoleContext);
  const { userDetails, setUserDetails } = useUser();

  const authorize = async (values: { email: string; password: string }) => {
    const {
      data: { accessToken, role, onboardingCompleted, type, name, companyName },
    } = await instanceWithToken.post<{
      name: string;
      companyName: string;
      accessToken: string;
      role: 'CLIENT' | 'MANAGER';
      onboardingCompleted: boolean;
      type: UserType;
    }>('/api/auth/login', values);
    localStorage.setItem('token', accessToken);
    setRole(role);
    setRoleType(type);

    setUserDetails({
      ...userDetails,
      userName: name,
      userType: type,
      companyName,
    });

    ReactGA.event({
      category: 'User',
      action: 'Logged in',
      label: `${values.email} - ${new Date().toISOString()}`,
    });

    if (
      !onboardingCompleted &&
      (role === 'MANAGER' || (role === 'CLIENT' && type === 'HUBSPOT'))
    ) {
      navigate(`/onboarding/1`);
      return;
    } else {
      // TODO change when we have a dashboard
      // if (type === 'HUBSPOT') {
      //   navigate('/dashboard');
      //   return;
      // }
      navigate('/');
    }
    return;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      loginPassword: '',
    },
    onSubmit: async (values) => {
      try {
        await authorize({
          email: values.email,
          password: values.loginPassword,
        });
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          switch (error.response.status) {
            case 401:
              ctx.updateToaster({
                content: 'Invalid credentials. Please try again',
                isError: true,
              });
              break;
            case 400:
              ctx.updateToaster({
                content: error.response.data.message,
                isError: true,
              });
              break;
            case 404:
              ctx.updateToaster({
                content:
                  "Looks like you don't have an account with us. Please register using the link below",
                isError: true,
              });
              break;
            case 409:
              ctx.updateToaster({
                content:
                  'Incorrect password. Please try again or reset a password',
                isError: true,
              });
              break;
            case 500:
              ctx.updateToaster({
                content: 'Internal server error. Please try again later',
                isError: true,
              });
              break;
            default:
              ctx.updateToaster({
                content: 'An unexpected error occurred',
                isError: true,
              });
          }
        } else {
          ctx.updateToaster({
            content: 'An error occurred. Please try again',
            isError: true,
          });
        }
      }
    },
    validate: () => {
      const errors: any = {};
      if (!formik.values.email.length) {
        errors.email = 'Email is required';
      }
      if (!formik.values.loginPassword.length) {
        errors.password = 'Password is required';
      }
      return errors;
    },
  });

  return { form: formik };
};
