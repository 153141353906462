import React from 'react';
import { Outlet } from 'react-router-dom';

const PublicLayout: React.FC = () => {
  return (
    <div className={'app-container'}>
      {/*<header style={{ padding: '1rem', background: '#eaeaea' }}>*/}
      {/*  <h1>MyApp Public</h1>*/}
      {/*</header>*/}
      {/*<main style={{ padding: '1rem' }}>*/}
      {/* Nested public routes will render here */}
      <Outlet />
      {/*</main>*/}
      {/*<footer style={{ padding: '1rem', background: '#eaeaea', textAlign: 'center' }}>*/}
      {/*  <p>&copy; {new Date().getFullYear()} MyApp</p>*/}
      {/*</footer>*/}
    </div>
  );
};

export default PublicLayout;
