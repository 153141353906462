import { useNavigate, useParams } from 'react-router-dom';
import { ReactNode, useContext, useMemo } from 'react';
import { RoleContext } from '../context/role.context';
import { useNewDocuments } from './bootstrap/components/documents/hooks/new-documents.hook';
import classNames from 'classnames';
import './base-page.style.css';
import { useUser } from '../context/user-details.context';
import { trackLogout } from '../tools/analytics-events';
import nextLogo from '../../../public/images/next-logo.png';

const headerLogoSvg = (
  <svg
    width="118"
    height="15"
    viewBox="0 0 118 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.7145 0.240994V9.28904C29.7145 12.0212 32.3256 14.4297 35.3726 14.4297C37.5918 14.561 41.454 14.5422 44.5922 14.5422C48.2049 14.5422 50.8527 14.4297 53.2975 14.4297C57.0473 14.4297 59.2754 11.9017 59.2754 9.28904V0.241026H45.4041V7.36242C45.4041 10.7091 45.4919 10.7091 44.5922 10.7091C43.6924 10.7091 43.786 10.7091 43.786 7.36242V0.241026L29.7145 0.240994Z"
      fill="black"
    />
    <path
      d="M28.3205 4.35151V0.240967H0V14.5431H14.2643V9.40641H28.3205V5.39659H14.2643V4.35151H28.3205Z"
      fill="black"
    />
    <path
      d="M74.6461 10.5333V9.46805H88.7363V5.19515H74.6461V4.2508H88.7363V0.24097H60.4158V14.5431H88.7363V10.5333H74.6461Z"
      fill="black"
    />
    <path
      d="M89.9468 14.5431H118V10.5949L103.942 10.5949V0.240968L89.9468 0.24097V14.5431Z"
      fill="black"
    />
  </svg>
);

export const HeaderLogo = ({ withNext }: { withNext: boolean }) => {
  if (!withNext) {
    return headerLogoSvg;
  }

  return (
    <div className="logo">
      <>
        <img
          src={nextLogo}
          alt="Next logo"
          style={{
            width: '118px',
            height: '30px',
            margin: '0',
          }}
        />
        <span
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            marginRight: '10px',
          }}
        >
          powered by
        </span>
      </>
      {headerLogoSvg}
    </div>
  );
};

const HeaderControlsButton = ({
  tabName,
  onClick,
  isSelected,
}: {
  tabName: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  const className = classNames('header-controls-button', {
    'header-selected': isSelected,
  });

  return (
    <button className={className} onClick={onClick}>
      {tabName}
    </button>
  );
};

const HeaderControls = () => {
  const { role, setRole, userType } = useContext(RoleContext);
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { clearDocumentsInterval } = useNewDocuments();
  const { userDetails } = useUser();

  const controls = useMemo(() => {
    const roleBasedHeaderControls = {
      BOOTSTRAP: [
        {
          name: 'Home',
          onClick: () => {
            const url = `/`;
            navigate(url);
          },
          isSelected: () => {
            return false;
          },
        },
        {
          name: 'Account',
          onClick: () => {
            const url = `/payment-details`;
            navigate(url);
          },
          isSelected: () => {
            return window.location.pathname.includes('payment-details');
          },
        },
      ],
      BOOTSTRAP_NEXT: [
        {
          name: 'Home',
          onClick: () => {
            const url = `/`;
            navigate(url);
          },
          isSelected: () => {
            return false;
          },
        },
      ],
      MANUAL: [],
      UNKNOWN: [],
      MANAGER: [],
      HUBSPOT: [],
      BOOTSTRAP_HUBSPOT: [],
    };

    if (!userType) {
      return [];
    }

    return roleBasedHeaderControls[userType];
  }, [companyId, navigate, role, userType]);

  if (!role) return null;

  return (
    <div className={'header-controls'}>
      {controls.map(
        (tab: {
          name: string;
          onClick: () => void;
          isSelected: () => boolean;
        }) => {
          const selected = tab.isSelected();
          return (
            <HeaderControlsButton
              key={tab.name}
              tabName={tab.name}
              onClick={tab.onClick}
              isSelected={selected}
            />
          );
        }
      )}
      <HeaderControlsButton
        tabName={'Logout'}
        onClick={() => {
          trackLogout({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
          localStorage.removeItem('token');
          localStorage.removeItem('userDetails');
          clearDocumentsInterval();
          setRole(null);
          navigate('/');
        }}
        isSelected={false}
      />
    </div>
  );
};

const Header = ({ displayControls }: { displayControls: boolean }) => {
  const { role, userType } = useContext(RoleContext);

  if (!role || !userType) return null;

  if (!['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType)) return null;

  if (!displayControls) return null;

  if (role === 'CLIENT') {
    return (
      <div className="header">
        <HeaderLogo withNext={userType === 'BOOTSTRAP_NEXT'} />
        <HeaderControls />
      </div>
    );
  }
};

export const BasePage = ({
  children,
  publicPage = false,
}: {
  children: ReactNode;
  mini?: boolean;
  publicPage?: boolean;
}) => {
  return (
    <div className="wrapper">
      <Header displayControls={!publicPage} />
      {children}
    </div>
  );
};
