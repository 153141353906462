import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { RoleContext } from './context/role.context';
import { publicRoutes } from './modules/public/routes/public.routes';
import { instanceWithToken } from './tools/api';
import { UserRole } from './tools/api-services/types/client';
import { Preloader } from './shared-components/preloader';
import { UserType } from './types/userType';

export const BootstrapHubspotRoutes = lazy(
  () => import('./modules/boostrap-hubspot/routes/bootstrap-hubspot.router')
);
export const ManagerRoutes = lazy(
  () => import('./modules/manager/routes/manager.routes')
);
export const BaseRoutes = lazy(
  () => import('./modules/base/routes/base.routes')
);
export const MainRoutes = lazy(
  () => import('./modules/main/routes/main.routes')
);
export const InvestorRoutes = lazy(
  () => import('./modules/investor/components/routes/investor.router')
);

interface AuthCheckHocProps {
  children: React.ReactNode;
}

export const AuthCheckHoc: React.FC<AuthCheckHocProps> = ({ children }) => {
  const { setRole, setRoleType, setAccessibleResources, role, userType } =
    useContext(RoleContext);
  const [authChecked, setAuthChecked] = useState(false);

  const check = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setAuthChecked(true);
      return;
    }
    try {
      if (role && userType) {
        setAuthChecked(true);
        return;
      }
      const result = await instanceWithToken.get<{
        role: UserRole;
        isAuthenticated: boolean;
        type: UserType;
      }>('api/auth/is-authenticated');
      if (result.data.role) {
        setRole(result.data.role);
        setRoleType(result.data.type);
      }

      if (result.data.role === 'CLIENT') {
        const { data: accessibleResources } = await instanceWithToken.get(
          '/api/me/accessible_resources'
        );

        if (accessibleResources.dashboard) {
          setAccessibleResources(['DASHBOARD']);
        }
      }
    } catch (e) {
      console.error('Auth check failed:', e);
    } finally {
      setAuthChecked(true);
    }
  };

  useEffect(() => {
    check();
  }, []);

  if (!authChecked) {
    return;
  }

  return <>{children}</>;
};

export const UpdatedAppRouter = () => {
  const { userType } = useContext(RoleContext);
  if (!userType) return null;
  return (
    <>
      <AuthCheckHoc>
        <Suspense fallback={<Preloader isLoading={true} />}>
          <Routes>
            {publicRoutes}
            {userType === 'BOOTSTRAP_HUBSPOT' && (
              <Route path="/*" element={<BootstrapHubspotRoutes />} />
            )}
            {userType === 'MANAGER' && (
              <Route path="/*" element={<ManagerRoutes />} />
            )}
            {(userType === 'BOOTSTRAP' || userType === 'BOOTSTRAP_NEXT') && (
              <Route path="/*" element={<BaseRoutes />} />
            )}
            {userType === 'HUBSPOT' && (
              <Route path="/*" element={<MainRoutes />} />
            )}
            {userType === 'INVESTOR' && (
              <Route path="/*" element={<InvestorRoutes />} />
            )}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Suspense>
      </AuthCheckHoc>
    </>
  );
};
