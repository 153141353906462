import React from 'react';
import { Route } from 'react-router-dom';
import { Login } from '../../../pages/login/login.page';
import { NextAuth } from '../../../pages/next-auth/next-auth.page';
import { BusinessModelPage } from '../../../pages/stepper/business-model/business-model.page';
import { DirectRegistrationPage } from '../../../pages/direct-registration/direct-registration/registration.page';
import { EmailVerification } from '../../../pages/direct-registration/email-verification/email-verification.page';
import { ForgotPasswordPage } from '../../../pages/forgot';
import { CompleteResetPage } from '../../../pages/complete-reset/complete-reset.page';
import { TextPage } from '../../../pages/dynamic-content';
import { BugReportPage } from '../../../pages/bug-contact/bug-report.page';
import { ReportIssuePage } from '../../../pages/bug-contact/report-issue.page';
import { FirebaseCallbackPage } from '../../../pages/firebase-callback/firebase-callback.page';
import PublicLayout from '../public.layout';
import { TutorialVideo } from '../../../pages/stepper/tutorial-video/tutorial-video.page';
import { ContactUsPage } from '../../../pages/bug-contact/contact-us.page';

// Export a fragment of Route elements.
export const publicRoutes = (
  <>
    {/* Wrap the public routes with the PublicLayout */}
    <Route element={<PublicLayout />}>
      <Route path="/login" element={<Login />} />
      <Route path="/login/next" element={<NextAuth />} />
      <Route path="/business-model" element={<BusinessModelPage />} />
      <Route path="/signup" element={<DirectRegistrationPage />} />
      <Route path="/verify-email" element={<EmailVerification />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route
        path="/complete-registration"
        element={<ForgotPasswordPage isComplete={true} />}
      />
      <Route
        path="/reset-success"
        element={<TextPage text="Instructions were sent to your email" />}
      />
      <Route path="/complete-reset" element={<CompleteResetPage />} />
      <Route path="/report-bug" element={<BugReportPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/support/report-issue" element={<ReportIssuePage />} />
      <Route path="/firebase-callback" element={<FirebaseCallbackPage />} />
      <Route path="/tutorials/:stepNumber" element={<TutorialVideo />} />
      {/* Fallback: redirect any unknown public URL to /login */}
    </Route>
  </>
);
