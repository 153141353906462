import axios from 'axios';
import ReactGA from 'react-ga4';

export class ApiInstance {
  instance = axios.create({
    baseURL: import.meta.env.VITE_APP_BACKEND_URL,
  });

  constructor() {
    this.instance.interceptors.request.use((config) => {
      const token = localStorage.getItem('token');

      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }

      ReactGA.event({
        category: 'API',
        action: `${config.method} api request`,
        label: config.url,
      });

      return config;
    });

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('role');
          localStorage.removeItem('userDetails');
          const params = new URLSearchParams(window.location.search);

          if (params.get('with-integration')) {
            window.location.href = `/login?with-integration=true`;
            return;
          }

          window.location.href = '/login';
        }
        throw err;
      }
    );
  }
}

export const instanceWithToken = new ApiInstance().instance;
