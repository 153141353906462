import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useNextLogin } from '../login/next-login.hook';

export const NextAuth = () => {
  const navigate = useNavigate();
  const { handleRedirectResult, handleOpenidRedirect, handlePopup } =
    useNextLogin();
  const env = import.meta.env.VITE_APP_ENVIRONMENT;

  const checkCustomToken = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const customToken = urlParams.get('customToken');

    if (customToken) {
      const user = await signInWithCustomToken(getAuth(), customToken);
      const token = await user.user.getIdToken();
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (env === 'development') {
      return;
    }
    checkCustomToken();
    if (!sessionStorage.getItem('openidInitialized')) {
      handleOpenidRedirect();
      sessionStorage.setItem('openidInitialized', 'true');
    }
    handleRedirectResult();
  }, [checkCustomToken]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      {env === 'development' && <button onClick={handlePopup}>BTN</button>}
    </div>
  );
};
